<script setup lang="ts">
const $props = defineProps<{
	event: App.Data.EventData
	allowedRidersCategoriesExampleFile: string | null
	allowedRidersCategoriesLatestUploadDate: string | null
	allowedRidersCategoriesLatestRowCount: number | null
}>()

const { user } = useUser()

const descriptionArea = ref<HTMLElement | null>(null)
onMounted(() => {
	descriptionArea.value?.querySelectorAll('a').forEach((link) => {
		link.setAttribute('target', '_blank')
	})
})

defineLayoutProperties({
	pageTitle: $props.event.name,
	pageTitleLink: route('organizers.show', { organizer: $props.event.organizer.uuid }),
})
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
	<page-container>
		<div v-if="event.banner_url" class="mb-4 flex justify-center">
			<img :src="event.banner_url" class="max-w-full" />
		</div>

		<div class="grid grid-cols-1 gap-4 sm:grid-cols-3">
			<div class="col-span-2">
				<heading3 class="mb-4">
					{{ t('app.details') }}
				</heading3>
				<div class="grid grid-cols-2 gap-x-4 gap-y-8 md:grid-cols-3">
					<div>
						<dt class="text-sm font-medium text-gray-500">
							{{ t('app.event_date') }}
						</dt>
						<dd class="mt-1 text-sm text-gray-900">
							{{ event.event_date }}
						</dd>
					</div>
					<div>
						<dt class="text-sm font-medium text-gray-500">
							{{ t('app.organizer') }}
						</dt>
						<dd class="mt-1 text-sm text-gray-900">
							{{ event.organizer.name }}
						</dd>
					</div>
					<div v-if="event.country">
						<dt class="text-sm font-medium text-gray-500">
							{{ t('app.country') }}
						</dt>
						<dd class="mt-1 text-sm text-gray-900">
							{{ event.country }}
						</dd>
					</div>
					<div v-if="event.country">
						<dt class="text-sm font-medium text-gray-500">
							{{ t('app.city') }}
						</dt>
						<dd class="mt-1 text-sm text-gray-900">
							{{ event.city }}
						</dd>
					</div>
					<div>
						<dt class="text-sm font-medium text-gray-500">
							{{ t('app.registration_opens_at') }}
						</dt>
						<dd class="mt-1 text-sm text-gray-900">
							{{ event.registration_open_date_display }}
						</dd>
					</div>
					<div>
						<dt class="text-sm font-medium text-gray-500">
							{{ t('app.registration_closes_on') }}
						</dt>
						<dd class="mt-1 text-sm text-gray-900">
							{{ event.registration_close_date_display }}h CET
						</dd>
					</div>
					<div>
						<dt class="text-sm font-medium text-gray-500">
							{{ t('app.registration_late_entry_closes_on') }}
						</dt>
						<dd v-if="event.registration_late_entry_close_date" class="mt-1 text-sm text-gray-900">
							{{ event.registration_late_entry_close_date_display }}h CET
						</dd>
						<dd v-else class="mt-1 text-sm text-gray-900">
							{{ t('app.not_possible') }}
						</dd>
					</div>
					<div>
						<dt class="text-sm font-medium text-gray-500">
							{{ t('app.registration_extra_late_entry_closes_on') }}
						</dt>
						<dd v-if="event.registration_extra_late_entry_close_date" class="mt-1 text-sm text-gray-900">
							{{ event.registration_extra_late_entry_close_date_display }}h CET
						</dd>
						<dd v-else class="mt-1 text-sm text-gray-900">
							{{ t('app.not_possible') }}
						</dd>
					</div>
					<div>
						<dt class="text-sm font-medium text-gray-500">
							{{ t('app.cancellation_closes_on') }}
						</dt>
						<dd v-if="event.registration_cancel_close_date" class="mt-1 text-sm text-gray-900">
							{{ event.registration_cancel_close_date_display }}h CET
						</dd>
						<dd v-else class="mt-1 text-sm text-gray-900">
							{{ t('app.not_possible') }}
						</dd>
					</div>
				</div>

				<template v-if="event.description">
					<heading3 class="mb-4 mt-8">
						{{ t('app.information') }}
					</heading3>
					<!-- eslint-disable vue/no-v-html -->
					<div ref="descriptionArea" class="prose max-w-none text-sm" v-html="event.description" />
				</template>

				<template v-if="event.event_categories">
					<heading3 class="mb-4 mt-8">
						{{ t('app.categories') }}
					</heading3>

					<table-base>
						<template #head>
							<table-row>
								<table-head class="pl-0">
									{{ t('app.category') }}
								</table-head>
								<table-head>{{ t('app.price') }}</table-head>
								<table-head>{{ t('app.price_late_entry') }}</table-head>
								<table-head>{{ t('app.price_extra_late_entry') }}</table-head>
							</table-row>
						</template>
						<table-row v-for="category in event.event_categories" :key="category.name">
							<table-column class="pl-0">
								{{ category.name }}
							</table-column>
							<table-column>
								<template v-if="category.prices_to_be_announced">
									{{ t('app.prices_to_be_announced') }}
								</template>
								<template v-else>
									&euro; {{ category.price }}
								</template>
							</table-column>
							<table-column v-if="category.late_entry_enabled">
								<template v-if="category.prices_to_be_announced">
									{{ t('app.prices_to_be_announced') }}
								</template>
								<template v-else>
									&euro; {{ category.late_entry_price }}
								</template>
							</table-column>
							<table-column v-else>
								{{ t('app.not_possible') }}
							</table-column>
							<table-column v-if="category.extra_late_entry_enabled">
								<template v-if="category.prices_to_be_announced">
									{{ t('app.prices_to_be_announced') }}
								</template>
								<template v-else>
									&euro; {{ category.extra_late_entry_price }}
								</template>
							</table-column>
							<table-column v-else>
								{{ t('app.not_possible') }}
							</table-column>
						</table-row>
					</table-base>
				</template>
			</div>
			<div class="flex flex-col items-start gap-4">
				<heading3>
					{{ t('app.actions') }}
				</heading3>

				<template v-if="!user || user?.type === 'rider' || (user?.type === 'entity' && !event.use_member_registrations)">
					<button-base v-if="(event.registration_open || event.registration_late_entry_open || event.registration_extra_late_entry_open) && event.show_registration_button" :href="route('events.registrations.index', { event: event.uuid })">
						<i-ph-note-pencil-bold class="h-4 w-4" />
						{{ t('app.sign_up') }}
					</button-base>
					<p v-else class="text-sm text-gray-700">
						{{ t('app.registration_not_enabled') }}
					</p>
				</template>
				<template v-else>
					<button-base :href="route('member-history.show', { event: event.uuid })">
						<i-ph-note-pencil-bold class="h-4 w-4" />
						{{ t('app.registrations_title') }}
					</button-base>
				</template>

				<button-base v-if="event.authorization.showEntries" :href="route('events.entries', { event: event.uuid })">
					<i-gg-user-list class="h-5 w-5" />
					{{ t('app.entries') }}
				</button-base>

				<template v-if="allowedRidersCategoriesExampleFile">
					<div class="w-full border-t border-gray-100">
						<h2 class="mb-2 mt-4 font-open-sans text-lg font-bold tracking-tight text-gray-900">
							{{ t('app.country_allowed_riders_category') }}
						</h2>

						<a :href="allowedRidersCategoriesExampleFile + '?v=' + Date.now()" target="_blank" class="focus-visible:primary-indigo-600 inline-flex items-center gap-x-2 bg-primary px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 disabled:cursor-not-allowed">
							<i-gg-export class="h-4 w-4" aria-hidden="true" />
							{{ t('app.download_example_file') }}
						</a>

						<div class="mt-4">
							<button-base :href="route('events.show-upload-allowed-riders-categories', { event: event.uuid })">
								<i-gg-import class="h-5 w-5" />
								{{ t('app.upload_file') }}
							</button-base>
						</div>

						<p v-if="allowedRidersCategoriesLatestUploadDate" class="mt-2 text-sm text-gray-700">
							<strong>{{ t('app.latest_upload_date') }}:</strong> {{ allowedRidersCategoriesLatestUploadDate }}<br />
							<strong>{{ t('app.latest_row_count') }}:</strong> {{ allowedRidersCategoriesLatestRowCount }}
						</p>
					</div>
				</template>
			</div>
		</div>
	</page-container>
</template>
